import en from '../components/i18nProvider/en';
import zh_cn from '../components/i18nProvider/zh_cn';
import { Iprefix } from '../hooks/useTranslation';

const enumTranslateKeys: Iprefix[] = [
  'enumConstants',
  'EnumAgentType',
  'EnumCreditAllocationStatus',
  'EnumRebateMode',
  'EnumRebateStatus',
  'EnumRebateStep',
  'EnumRebateTaskStatus',
  'EnumRebateType',
  'EnumQuestionType',
  'EnumIdvMethod',
];

export const getEnumTranslationEntries = (translateEnumObj: typeof en | typeof zh_cn) =>
  Object.entries(translateEnumObj).reduce((acc: [string, any][], [key, val]) => {
    if (enumTranslateKeys.includes(key as Iprefix)) {
      return [...acc, ...Object.entries(val)];
    }

    return acc;
  }, []);
