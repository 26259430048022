import { EnumIdvMethod } from "@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master";


export const getIdvMethodDisplayValue = (
  idvMethod: number,
  translateFunc: (key: string) => string
) => {
  const value = EnumIdvMethod[idvMethod];
  return translateFunc(`EnumIdvMethod.${value}`)
}
