import APIs from "../../../../../api";
import { DialogInOne } from "../../../../../components";
import { IdialogInOneProps } from "../../../../../components/DialogInOne";
import { GridBox } from "../../../../../components/Layout";
import { Box } from "../../../../../components/MuiGenerals";
import { useAlerting, useTranslation } from '../../../../../hooks'
import { Iprefix } from "../../../../../hooks/useTranslation";
import { useZusDialogStore } from "../../../../../zustand/store";
import { useZusParams } from "../../hooks";
import { filterTableTranslateKey as TK } from '../../mappings/translate';
import { IapiGetAllRes } from "../../types";

const RetryDialog = () => {
  const translatePrefix: Iprefix = 'creditSwapRecord';
  const { t } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();
  const { alerting } = useAlerting();

  const {
    id = '',
    targetTransferOrderId = '',
    customerNumber = '',
    assetCurrency = '',
    assetAmount = '',
    exchangeRate = '',
    creditCurrency = '',
    creditAmount = ''
  } = (zusDialog.meta as IapiGetAllRes | undefined) || {}

  const labelElePairArr: [string, JSX.Element][] = [
    [
      TK.targetTransferOrderId,
      <Box>{targetTransferOrderId}</Box>
    ],
    [
      TK.pfhNumber,
      <Box>{customerNumber}</Box>
    ],
    [
      TK.asset,
      <Box>{assetCurrency}</Box>
    ],
    [
      TK.assetAmount,
      <Box>{assetAmount}</Box>
    ],
    [
      TK.exchangeRate,
      <Box>{exchangeRate}</Box>
    ],
    [
      TK.creditCurrency,
      <Box>{creditCurrency}</Box>
    ],
    [
      TK.creditAmount,
      <Box>{creditAmount}</Box>
    ],
  ];

  const onConfirm = async () => {
    const res = await APIs.programAgentManagement.creditSwapRecord.postRetry({
      ...zusParams.body,
      creditSwapId: id
    });
    if (!res) { return; }
    await onClose();
    alerting('success', t(TK.retrySuccessfully));
    zusParams.refetch();
  }

  const onClose = async () => {
    await zusDialog.close();
  };

  const dialogConfig: IdialogInOneProps = {
    title: t(TK.confirmToRetryTheRecord),
    self: {
      open: zusDialog.match('creditSwapRecordRetryDialog'),
      onClose: onClose
    },
    content: <GridBox labelElePairArr={labelElePairArr} />,
    onConfirm,
    onCancel: onClose
  }

  return <DialogInOne {...dialogConfig} />
}

export default RetryDialog