import { useQuery } from 'react-query';

import { GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import APIs from '../../../../api';
import TableTemplate from '../../../../components/Layout/TableTemplate';
import { usePermission } from '../../../../hooks';
import { useGenTableColumns } from '../../../../hooks/useTable';
import useTranslation, { Iprefix } from '../../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../../zustand/store';
import { useListMapping, useZusParams } from '../hooks';
import {
  filterTableTranslateKey as TableTK,
  retryDialogTranslateKey as RetryTK
} from '../mappings/translate';
import ButtonMenu from '../../../../components/Button/ButtonMenu';
import { IapiGetAllRes } from '../types';
import { EnumCreditAllocationStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

const api = {
  table: APIs.report.creditAllocationRecord.getAll,
  export: APIs.report.creditAllocationRecord.getExport,
};

export default function Table() {
  const { hasPermission } = usePermission();
  const translatePrefix: Iprefix = 'creditAllocationRecord';
  const exportFileName = 'Credit Allocation Record';
  const exportPermissionKey = AgentPortalFeatureCode.Report.CreditAllocationRecord.Export;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { t, tc } = useTranslation(translatePrefix);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const tableMapping = useListMapping('table');
  const exportMapping = useListMapping('export');

  const omitKeys: string[] = [];

  const content: GridRowsProp = tableMapping(rows, omitKeys);

  const renderOperationCell = (params: GridRenderCellParams) => {
    const getAllData = params.row['rawData'] as IapiGetAllRes;
    const shouldDisableRetry =
      !(getAllData.status === EnumCreditAllocationStatus.ExecuteFailed ||
        getAllData.status === EnumCreditAllocationStatus.Stucking)

    return <ButtonMenu
      title={t(TableTK.operation)}
      options={[
        {
          name: t(RetryTK.retry),
          onClickFn: () => {
            zusDialog.open('retryDialog', getAllData)
          },
          isDisabled: shouldDisableRetry
        }
      ]}
    />
  }


  const { columns } = useGenTableColumns([
    { translateKey: TableTK.operation, renderCell: renderOperationCell },
    { translateKey: TableTK.transferOrderId, minWidth: 150 },
    { translateKey: TableTK.allocationType },
    { translateKey: TableTK.from, minWidth: 150 },
    { translateKey: TableTK.to, minWidth: 150 },
    { translateKey: TableTK.creditCurrency },
    { translateKey: TableTK.creditAmount, minWidth: 150 },
    { translateKey: TableTK.status, minWidth: 150 },
    { translateKey: TableTK.createdBy },
    { translateKey: TableTK.creationTime },
    { translateKey: TableTK.lastModifiedBy, minWidth: 150 },
    { translateKey: TableTK.lastModifiedTime },
  ]);

  return (
    <>
      <TableTemplate
        exportBtn={{
          fileName: exportFileName,
          permissionKey: exportPermissionKey,
          apiFn: api.export,
          omitKeys: ['rawData'],
          mappingFn: exportMapping,
        }}
        zusParams={zusParams}
        count={count}
        content={content}
        columns={columns}
      />
    </>
  );
}
