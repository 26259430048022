import { GridRenderCellParams } from '@mui/x-data-grid';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { usePermission } from '../../../../hooks';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/Table';

interface IProps {
  renderViewAssignedToCell: (params: GridRenderCellParams) => JSX.Element;
}

const useTableColumns = (props: IProps) => {
  const { renderViewAssignedToCell } = props;
  const { hasPermission } = usePermission();

  const isHideViewAssignedToColumn = !hasPermission(
    AgentPortalFeatureCode.ProgramAgentManagement.ProgramList.ViewAssignedTo.prefix
  );

  const columns = [
    useGenGridCol(TableColumnKey.ProgramName),
    useGenGridCol(TableColumnKey.ProgramDisplayName),
    useGenGridCol(TableColumnKey.ProgramCurrency),
    useGenGridCol(TableColumnKey.QuestionType),
    useGenGridCol(TableColumnKey.KycIdvMethod),
    useGenGridCol('assignedTo', {
      renderCell: renderViewAssignedToCell,
      hide: isHideViewAssignedToColumn,
    }),
    useGenGridCol(TableColumnKey.CreationTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.LastModifiedTime, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
