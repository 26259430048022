import {
  EnumCreditAllocationStatus,
  EnumCreditAllocationType,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

import { IdateFilter, Ipage } from '../../../../api/types';
import { useListMappingTransform, useTranslation } from '../../../../hooks';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { initZusParams } from '../../../../utils/config';
import { createZusInstance } from '../../../../zustand/store';
import { filterTableTranslateKey as TK } from '../mappings/translate';
import { IapiGetAllRes } from '../types';

export interface IsearchParams extends IdateFilter {
  targetTransferOrderId: string;
  type: string;
  fromWalletClientId: string;
  toWalletClientId: string;
  status: string;
}

type ApiParam = Ipage & Partial<IsearchParams>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const useListMapping = (mode: 'export' | 'table') => {
  const { t, tc } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);

  const listMapping = (array: IapiGetAllRes[], omitKeys: string[] = []) => {
    const res = array.map((item) => {
      const displayCreditAmount = displayAmountCurrying(item.creditCurrencyDecimals);
      const mapping: Array<[string, any | number | string]> = [
        ['rawData', item],
        [TK.transferOrderId, item.targetTransferOrderId],
        [TK.allocationType, t(EnumCreditAllocationType[item.type])],
        [TK.from, item.fromWalletClientId],
        [TK.to, item.toWalletClientId],
        [TK.creditCurrency, item.creditCurrency],
        [TK.creditAmount, displayCreditAmount(item.creditAmount)],
        [TK.status, t(EnumCreditAllocationStatus[item.status])],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedBy, item.lastModifiedBy],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ];

      const mappedResult = mapping.filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export { useZusParams, useListMapping };
