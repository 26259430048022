const filterTableTranslateKey = {
  operation: 'operation',
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  transferOrderId: 'transfer_order_id',
  allocationType: 'allocation_type',
  from: 'from',
  to: 'to',
  creditCurrency: 'credit_currency',
  creditAmount: 'credit_amount',
  status: 'status',
  lastModifiedBy: 'last_modified_by',
  createdBy: 'created_by',
};

const retryDialogTranslateKey = {
  ...filterTableTranslateKey,
  retry: 'retry',
  confirmToRetryTheRecord: 'confirm_to_retry_the_record',
  retrySuccessfully: 'retry_successfully'
}

export { filterTableTranslateKey, retryDialogTranslateKey };
